<template>
        <div id="wrapper">
            <hr>
        <v-footer class="footer">
          <v-flex>
              <span>
                <a  class="txt" id="loadaccount" v-if="showlink" v-on:click.prevent="loadAccount">My Account</a>  
                <span  v-if="showlink" class="pipe">&middot;</span>
                <a class="txt" v-on:click.prevent="loadTerms">Terms of Use</a>
              </span>
                <p justify-end> Copyright &copy; {{ new Date().getFullYear() }} American Osteopathic Association</p>
          </v-flex>
    </v-footer>
        </div>
</template>

<script>

import { EventBus } from '../eventbus.js';
export default {
	props: [
		'authuser'
	],
	data: () => ({
		showlink : false
	}),
	methods: {
		loadAccount (e) {
			EventBus.$emit('footer-click', 'myaccount');
		},
		loadTerms(e) {
			EventBus.$emit('footer-click', 'terms');
		}
	},
	mounted () {
		if (this.authuser !== undefined && this.authuser.length !== 0) {
			this.showlink = true;
		}
	}
};
</script>

<style scoped>
    .footer {
        position: fixed !important;
        left: 0;
        bottom: 0;
        width: 100%;
        background: rgb(2,0,36);
        background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(51,97,149,1) 35%, rgba(0,212,255,1) 100%);
        text-align: center;
    }
    a {
        color:#fff !important;
    }
    #wrapper {
        position: relative;
    }
    .pipe {
        color: #fff
    }
    .txt:hover {
        text-decoration: underline;
    }
</style>


