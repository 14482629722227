<template>
    <v-card flat >
        <v-toolbar color="background-prop" dark flat>
            <menumin-component v-if="!homeonly"
                :validResultsList="validResultsList"
            >
            </menumin-component>

            <v-img v-else class="hidden-sm-and-up" src="images/AOA-Horz-W_2018.png" contain max-height="100%" width="200"></v-img>

            <v-row align="center">
				<v-col cols="12" sm="4" class="pa-2">
					<v-img class="hidden-xs-only" src="images/AOA-Horz-W_2018.png" contain max-height="100%" width="200"></v-img>
				</v-col>

				<v-col cols="12" sm="4" class="justify">
					<v-card-title title class="layout justify-center hidden-sm-and-down font-weight-bold text-no-wrap"> CODE: Center for Osteopathic Data on Education</v-card-title>
				</v-col>

                <v-col cols="12" sm="4" class="text-right">            
                    <div align-end justify-end class="pa-3">
                            <div align-end justify-end v-if="homeonly" class="my-4" :style="dynamicStyle">
                                <span  text-align="center">
                                    <v-btn small color="white" text @click.once="loginHeader">{{ buttonTxt }} </v-btn>  
                                    |
                                    <v-btn small color="white" text @click.once="forgotPassword">Forgot password?</v-btn> 
                                </span>
                            </div>

                            <div align-end justify-end v-if="!homeonly" class="my-4" :style="dynamicStyle">
                                <span  text-align="center" >
                                <v-btn small  color="white" text @click="logout">{{ buttonTxt }}</v-btn>
                                |
                                <v-btn small  color="white" text :disabled="currentindex || computedDisabledStatus" @click="goToMyAccount">My Account</v-btn>
                                </span>
                            </div>      
                    </div>
                </v-col>
            </v-row>
          
            <template v-slot:extension>
                <v-tabs  v-model="model" centered flat :background-color="btnStyles">      
                        <v-tab
                        class="hidden-xs-only"
                        scrollable="false"
                        v-for="(i, index) in validResultsList"
                        :key="index"
                        :href="`#tab-${index}`"
                    >
                        <v-card-subtitle class="font-weight-bold">
                            {{ i }}
                        </v-card-subtitle>
                    </v-tab>
                </v-tabs>
            </template>
        </v-toolbar>

        <v-tabs-items v-model="model">
            <v-tab-item
                v-for="(i, index) in validResultsList"
                :key="index"
                :value="`tab-${index}`"
            >
                <v-card flat min-height="900" v-if="!terms" scrollable="false">
                        <v-card-title
                            class="display-2 justify-center" primary-title
                            v-if="!homeonly && i === 'Home' && !showaccountinfo"
                        >
                            {{ home_template_title }}
                        </v-card-title>

                        <v-card-title
                            class="display-1 justify-center"
                            v-else-if="homeonly && i === 'Home'"
                        >
                            {{ home_template_title_noauth }}
                        </v-card-title>

                        <v-card-text
                            class="headline justify-center"
                            v-if="!homeonly && i === 'Home'"
                        >
                            <v-card max-width="600" class="mx-auto" flat>
                                <v-container fluid class="pa-0">
                                    <v-row align="center" wrap>
                                        <v-col cols="12" sm="12">
                                            <div class="text-center">
                                                <div class="my-2">
                                                    <v-card-text
                                                        class="title
                                                        justify-center"
                                                    >
															{{ home_template_text }}
															    <v-spacer class="col-auto"></v-spacer>
															<p v-html="home_template_text_cont"> </p>
                                                        <v-spacer
                                                            class="col-auto"
                                                        ></v-spacer>
                                                    </v-card-text>
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>
                                <v-card-text headline style="text-align:center" v-if="computedUser">
                                    <v-icon icon="mdi-login" color="blue">
                                        mdi-lock-open-variant
                                    </v-icon>
										<v-card-text>
											{{ messageStr }}, {{ authuser.firstname ?  authuser.firstname : authuser.email }},
											{{ is_logged_in }}
										</v-card-text>

                                        <div class="max-w-2xl mx-auto py-16">
                                            <p style="display:none">{{ isAppIdle ? dialog = true : false }}</p>
                                            <v-dialog v-model="dialog"  width="400">
                                                <timer-component v-if="isAppIdle"/>
                                            </v-dialog>
                                        </div>
                                </v-card-text>
                            </v-card-text>

                        <v-card-text
                            class="headline justify-center"
                            v-else-if="homeonly && i === 'Home'"
                        >
                            <v-card max-width="600" class="mx-auto" flat>
                                <v-container fluid class="pa-0">
                                    <v-row align="center" wrap>
                                        <v-col cols="12" sm="12">
                                            <div class="text-center">
                                                <div class="my-2">
                                                    <v-card-text
                                                        headline
                                                        justify-center
                                                    >
													<v-card-subtitle>
														{{ home_template_text_noauth }} 

													</v-card-subtitle>
                                                       
														 <v-spacer
                                                            class="col-auto"
                                                        ></v-spacer>
													   
													    <v-card-subtitle v-html="home_template_text_noauth_cont"></v-card-subtitle>
                                                    </v-card-text>
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>

                            <v-btn
                                id="largelogin"
                                :loading="loadingAnim"
                                x-large
                                color="#22A49C"
                                @click.once.prevent="login"
                                dark
                                >Sign In</v-btn
                            >
                        </v-card-text>
                    <v-card 
                        flat
                        class="display justify-center font-weight-thin"
                        v-if="i === 'Cert Boards'"
                    >
                        <div v-if="!reporttable">
                            <v-card-title
                                class="display-1 justify-center font-weight-thin"
                                v-if="i === 'Cert Boards'"
                            >
                                {{ certboard_template_title }}
                            </v-card-title>

                            <v-card-text class="title">
                                {{ certboard_template_text }}
                            </v-card-text>
                        </div>
                        <div v-if="reporttable">
                            <v-card-title class="display-1 justify-center font-weight-thin">
                                {{ reportname }}
                            </v-card-title>
                        </div>

                        <certs-component :authuser="authuser" 
                        :states="states"
                        :approvalcodes="approvalcodes"
                        :completioncodes="completioncodes"
                        :trainingtypes="trainingtypes"
                        >
                        </certs-component>
                    </v-card>

                    <v-card
                        flat
                        class="headline justify-center font-weight-thin"
                        v-if="i === 'Specialty Colleges'"
                    >
                        <div v-if="!reporttable">
                            <v-card-title
                                class="display-1 justify-center font-weight-thin"
                            >
                                {{ specialty_template_title }}
                            </v-card-title>

                            <v-card-text  class="title">
                                {{ specialty_template_text }}
                            </v-card-text>
                        </div>

                        <div v-if="reporttable">
                            <v-card-title class="display-1 justify-center font-weight-thin">
                                {{ reportname }}
                            </v-card-title>
                        </div>

                        <specialty-component
                            :authuser="authuser"
                            :states="states"
                            :colleges="colleges"
                            :approvalcodes="approvalcodes"
                            :completioncodes="completioncodes"
                            :trainingtypes="trainingtypes"
                        >
                        </specialty-component>
                    </v-card>

                    <v-card
                        flat
                        class="headline justify-center font-weight-thin "
                        v-if="i === 'OPTI' && !terms"
                    >
                    <div v-if="!reporttable">

                        <v-card-title
                            class="display-1 justify-center font-weight-thin"
                            v-if="i === 'OPTI'"
                        >
                            {{ opti_template_title }}
                        </v-card-title>

                        <v-card-text class="title">
                            {{ opti_template_text }}
                        </v-card-text>
                    </div>

                     <div v-if="reporttable">
                        <v-card-title class="display-1 justify-center font-weight-thin">
                            {{ reportname }}
                        </v-card-title>

                        </div>
                        <opti-component
                            :authuser="authuser"
                            :states="states"
                            :colleges="colleges"
                            :approvalcodes="approvalcodes"
                            :completioncodes="completioncodes"
                            :trainingtypes="trainingtypes"
                        >
                        </opti-component>
                    </v-card>
                    <v-card-text v-if="i === 'Administrators'">
                        <keep-alive>
                        <admintable-component :admin-users="adminUsers">
                        </admintable-component>
                        </keep-alive>
                    </v-card-text>

                    <v-card-text
                        v-if="i === 'Update Profile' && showaccountinfo"
                    >
                        <account-component :authuser="authuser">
                        </account-component>
                    </v-card-text>
                </v-card>
                    <terms-component v-if="terms" :authuser="authuser"> </terms-component>
            </v-tab-item>
        </v-tabs-items>

        <v-dialog v-model="dialog5" persistent width="620">
            <v-card>
                <v-card-title class="title"
                    >We are sorry. You are not authorized to use the CODE
                    system.
                </v-card-title>
                
                <v-card-text class="blockquote justify-content-center">
                    If you are with an AOA Certifying Board or Specialty
                    College, please be sure that you have logged in with your
                    institution's AOA ID and password. Please contact
                    <a id="emailrejected" href="mailto:info@osteotech.org"
                        >info@osteotech.org</a
                    >
                    with any problems or questions.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn color="primary" text @click.prevent="dialog5=false; inlogin=false;logout()">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import states from '../states.js';
import $ from 'jquery';
import { EventBus } from '../eventbus.js';
import Vue from 'vue';

const BASE_URL = process.env.MIX_APP_URL;
const PROD_SSO_URL = process.env.MIX_PROD_SSO_URL;
const DEV_SSO_URL = process.env.MIX_DEV_SSO_URL;

export default {
	name: 'home',
	props: [
		'isadmin',
		'adminUsers', 'authuser', 'colleges', 
		'states', 'rejected',
		'completioncodes', 
		'approvalcodes', 
		'trainingtypes'
	],
	components:  {
		'admintable-component': () => import('./AdminTableComponent.vue'),
		'account-component': () => import ('./MyAccountComponent.vue'),
		'terms-component' : () => import ('./TermsComponent.vue'),
		'Grid' : () => import(Grid),
		'grid-toolbar' : () => import (GridToolbar),
		'certs-component' : () => import ('./TabContentCertComponent.vue'),
		'specialty-component' : () => import('./TabContentSpecialtyComponent.vue'),
		'opti-component': () => import('./TabContentOptiComponent.vue'),
		'menumin-component': () => import('./MenuMinComponent.vue'),
		'timer-component': () => import('./Timer.vue')
	},
    
	data: () => ({
		index: 0,
		length: null,
		loadingAnimation: false,
		model: 0,
		hamburger: false,
		terms: false,
		dialog: false,
		dialog1: false,
		dialog2: false,
		dialog3: false,
		dialog5: false,
		homeonly: true,
		showaccountinfo: false,
		currentTab: '',  
		messageStr:'Hello',
		reporttable: false,
		reportname: '',
		bgColor: {
			default: '#11c7eb',
			nonauth: '#fff'
		},
		changed: false,
		disabled: false,
		absolute: false,
		openOnHover: false,
		value: false,
		closeOnClick: true,
		closeOnContentClick: true,
		offsetX: false,
		offsetY: true,
		inlogin: false,
		canlogin: false,
		email: 'postdoc@osteopathic.org',

		form_text: {
			reset_form: 'Reset form',
			close_form: 'Close',
			search_form: 'Search'
		},

		tab_names: [
			'Home',
			'Cert Boards',
			'Specialty Colleges',
			'OPTI',
			'Administrators'
		],

		home_template_title: `
            Welcome to CODE: Center for Osteopathic Data on Education!
        `,

		home_template_title_noauth: `
            Welcome to CODE! 
        `,

		home_template_text_noauth: `
			Center for Osteopathic Data on Education. CODE is the American Osteopathic Association's (AOA's) online solution for the exchange of osteopathic education-related data. With this system, you may verify the postdoctoral training, membership and certification status of current and past trainees within your specialties.`,
			
        home_template_text_noauth_cont: `If you have questions about this system, please contact the AOA Department of Education at <a href="mailto:postdoc@osteopathic.org">postdoc@osteopathic.org</a>. Currently, only Certifying Boards, Specialty Colleges and OPTIs can register for access.`,

		home_template_text: `
            CODE is the American Osteopathic Association's (AOA's) online solution for the exchange of osteopathic education-related data.
            With this system, you may verify the postdoctoral training, membership and certification status of current and past trainees within your specialties.
        `,

		home_template_text_cont: `If you have questions about this system, please contact us at <a href="mailto:postdoc@osteopathic.org">postdoc@osteopathic.org</a>`,

		certboard_template_title: `
            Hello American Osteopathic Association,
        `,

		certboard_template_text: `
            Below is a listing of specialties for which you are able to view physician records.
            You can create an initial certification report, or a re-certification report based upon selected criteria by clicking below
            and filling out the search form
        `,

		specialty_template_title: `
            Hello American Osteopathic Association,
        `,

		specialty_template_text: `
            Below is a listing of specialties for which you are able to view physician records.
            You can create an initial certification report, or a re-certification report based upon selected criteria by clicking below
            and filling out the search form
        `,

		opti_template_title: `
            Hello American Osteopathic Association,
        `,

		opti_template_text: `
            Below is a listing of specialties for which you are able to view physician records.
            You can create a trainee report based upon selected criteria by clicking below and filling out the search form
        `,
		is_logged_in: 'you are logged in'
	}),
	methods: {
		login(e) {
			if (this.dialog5){
				e.preventDefault();
				return;
			}

			this.inlogin = true;
			if (e.target.ID == 'largelogin') {
				this.loadingAnimation = true;
			}

			let returl = `${BASE_URL}sso?token=`;

			if (process.env.MIX_APP_ENV.toLowerCase() != 'production') {
				window.location = `${DEV_SSO_URL}login/?serviceID=9&sendloginemail=0&onsuccessurl=${returl}`;
			} else {
				window.location = `${PROD_SSO_URL}login/?serviceID=9&sendloginemail=0&onsuccessurl=${returl}`;
			}
		},
		loginHeader() {
			if (this.dialog5){
				return;
			}
			this.inlogin = true;
			let returl = `${BASE_URL}sso?token=`;
			if (process.env.MIX_APP_ENV.toLowerCase() != 'production') {
				window.location = `${DEV_SSO_URL}login/?serviceID=9&sendloginemail=0&onsuccessurl=${returl}`;
			} else {
				window.location = `${PROD_SSO_URL}login/?serviceID=9&sendloginemail=0&onsuccessurl=${returl}`;
			}
		},
		logout() {
			window.location = `${BASE_URL}deletetoken`;
		},
		errorLogout() {
			setTimeout(()=> this.logout(), 3000);
		},
		forgotPassword() {
			window.location = `${PROD_SSO_URL}login/forgotpassword/`;
		},
		displayaccountinfo(e) {
			this.showaccountinfo = !this.showaccountinfo;
		},
		goToMyAccount (e) {
			this.disabled = true;
			if (this.tab_names.includes('Update Profile')) {
				let index = this.tab_names.length - 1;
				this.model = `tab-${index}`;
			}
			this.length++;
		},
		scrollToTop() {
			window.scrollTo(0,0);
		},
		clickHandler(payload) {
			if (payload == 'terms') {
				this.terms = true;
				return;
			}

			if (payload == 'myaccount') {
				this.disabled = true;
				if (this.tab_names.includes('Update Profile') && this.authuser.length) {
					let index = this.tab_names.length - 1;
					this.model = `tab-${index}`;
					this.currentTab = 'Update Profile';
					this.scrollToTop();
				}
			} 
            
			this.length++;
			this.scrollToTop();
		},
		clickHandlerClose() {
			this.terms = false;
		},
		hideHomeTab() {
			$('.v-tabs v-tabs--centered theme--dark').hide();
			$('[role=tab]').hide();
		},
		showTabs() {
			$('.v-item-group').show();
			$('[role=tab]').show();
		},
		displayMenu() {
			this.harmburger = true;
		},
		onIdle() {

		},
		onActive() {
			this.messageStr = 'Hello';
		},
		showReportType(ref) {
			this.reporttable = true;
			this.reportname = '';

			switch(ref) {
			case 'cert':
				return this.reportname = 'Certification Board Initial Certification Report' ;
			case 'recert':
				return this.reportname = 'Certification Board ReCertification Report' ;
			case 'specialty':
				return this.reportname = 'Specialty College Initial Certification Report';
			case 'specialtyrecert' :
				return this.reportname = 'Specialty College ReCertification Report';
			case 'opti': 
				return this.reportname = 'Trainee Report';
			}
		},
		closeReportText() {
			this.reporttable = false;
		},
		menuClickHandler(idx){
			this.model = `tab-${idx}`;
		}
	},

	watch: {
		length(val) {
			if (this.tab_names.includes('Update Profile')) {
				let index = this.tab_names.length - 1;
				this.model = `tab-${index}`;
				return;
			}

			if ( this.authuser !== undefined && this.authuser.length !== 0) {

				Vue.set(this.tab_names, this.tab_names.length, 'Update Profile');
				const newLength = this.tab_names.length++;
				this.tab_names.splice(newLength);
				this.model = `tab-${this.tab_names.length - 1}`;
				this.displayaccountinfo();
			}
		}
	},
	mounted() {

		if ( this.rejected ) {
			this.dialog5 = true;
		}

		if (this.authuser.length == 0 || typeof this.authuser.membertype == 'undefined' || !['A-SG', 'CB', 'OPTI'].includes(this.authuser.membertype)) {
			this.homeonly = true;
			this.hideHomeTab();
		} else {
			this.showTabs();
		}

		if(this.isadmin) {
			this.homeonly = false;
			this.showTabs();
		}

		EventBus.$on('footer-click', this.clickHandler);
		EventBus.$on('close', this.clickHandlerClose);

		EventBus.$on('reportmounted', this.showReportType);
		EventBus.$on('reportclosed', this.closeReportText);
		EventBus.$on('menu-click', this.menuClickHandler);


		const self = this;
		$('.v-tabs', '#loadaccount').click(function(e){
			self.currentTab = $(e.target).closest('a')[0].text;
			if (self.currentTab != 'Update Profile'){
				self.disabled = false;
			}
		});

	},
	updated () {
		if (this.tab_names.includes('Update Profile')){
			$('.v-tabs').off('click');
			const self = this;
			$('.v-tabs').on('click', function(e){
				self.currentTab = $(e.target).closest('a')[0].text;
				if (self.currentTab != 'Update Profile'){
					self.disabled = false;
				}
			});
        
		}
	},
	beforeDestroy() {
		this.rejected = false;
	},
	computed: {
		validResultsList() {             
			if (this.isadmin) {
				this.homeonly = false;
				return this.tab_names;
			} 

			if (typeof this.authuser != 'undefined' && this.authuser.length !== 0) {
				this.homeonly = false;

				const index = this.tab_names.indexOf('Administrators');
				if (index > -1) {
					this.tab_names.splice(index, 1);
				}

				switch (this.authuser.membertype) {
				case 'A-SG':
					const ASG_INDEX1 = this.tab_names.indexOf(
						'Cert Boards'
					);

					if (ASG_INDEX1 > -1) {
						this.tab_names.splice(ASG_INDEX1, 1);
					}

					const ASG_INDEX2 = this.tab_names.indexOf('OPTI');

					if (ASG_INDEX2 > -1) {
						this.tab_names.splice(ASG_INDEX2, 1);
					}

					return this.tab_names;

				case 'CB':
					const CB_INDEX1 = this.tab_names.indexOf(
						'Specialty Colleges'
					);

					if (CB_INDEX1 > -1) {
						this.tab_names.splice(CB_INDEX1, 1);
					}

					const CB_INDEX2 = this.tab_names.indexOf('OPTI');

					if (CB_INDEX2 > -1) {
						this.tab_names.splice(CB_INDEX1, 1);
					}

					return this.tab_names;

				case 'OPTI':
					const OPTI_INDEX1 = this.tab_names.indexOf(
						'Specialty Colleges'
					);

					if (OPTI_INDEX1 > -1) {
						this.tab_names.splice(OPTI_INDEX1, 1);
					}

					const OPTI_INDEX2 = this.tab_names.indexOf(
						'Cert Boards'
					);

					if (OPTI_INDEX2 > -1) {
						this.tab_names.splice(OPTI_INDEX2, 1);
					}

					return this.tab_names;

				default:
					this.dialog5 = true;
					this.homeonly = true;
					return this.tab_names = ['Home'];
				}
			} else {
                    
				this.homeonly = true;
				return this.tab_names = ['Home'];
			}
		},
		buttonTxt() {
			if (typeof this.authuser != 'undefined' || this.authuser.length > 0) {
				if (['A-SG', 'CB', 'OPTI'].includes(this.authuser.membertype) || this.isadmin) {
					return 'Log out';
				} else {
					return 'Log in';
				}
			}
		},
		computedAccountSearch() {
			if (this.showaccountinfo) {
				return true;
			} 
			return false;
		},
		computedDisabledStatus(){
			return this.disabled;
		},
		currentindex() {
			if (this.currentTab.trim() == 'Update Profile') {
				return true;
			}
			return false;
		},
		loadingAnim() {
			return this.loadingAnimation;
		},
		btnStyles() {
			if ( this.authuser !== undefined && this.authuser.length !== 0) {
				return this.bgColor.default;
			} else {
				return this.bgColor.nonauth;
			}
		},
		computedUser() {
			return this.authuser;
		},
		viewportHeight () {
			switch (this.$vuetify.breakpoint.name) {
			case 'xs': return true;
			case 'sm': return true;
			case 'md': return false;
			case 'lg': return false;
			case 'xl': return false;
			}
		},
		dynamicStyle() {
			if (this.viewportHeight) {
				return 'position: justify-center; padding-bottom:40px';
			} else {
				return 'position: justify-center;';
			}
		}

	}
};
</script>

<style>

.v-stepper{
    box-shadow: none;
}

.v-tabs{
    box-shadow: none;
}
header {
    background-color: #000066 !important;
}

#emailrejected {
    color: #336195 !important;
}

#largelogin {
    width: 15%;
    border: none;
    margin:0 auto;
    display:block;
}

[data-role="v-782dcf83"] {
    background-color: #11c7eb;
}

.k-grid-header,
.k-grid-header-wrap,
.k-grid-footer-wrap,
.k-grid thead,
.k-grid tbody,
.k-grid tfoot {
    color: #000066 !important;
    background-color: #fff;
}

section {
    margin-top: 20px;
}

.tblDetail {
    width: 800px;
    margin: 0 auto;
}

.tblDetail tr td {
    text-align: left;
}

.headerBlue {
    color: #336699;
    font-size: 16px;
}

.mbold {
    font-weight: bold;
}
.mboldSmall {
    font-weight: 800;
    font-size: 8pt;
}

.fontSmall {
    font-size: 8pt;
    font-weight: normal;
}

.mblock {
    display: block;
}

.k-grid-header-wrap table .k-header {
    background-color: #000066;
    color: #fff;
}

/* .k-button .k-button-icontext .k-grid-pdf, */
.v-application am {
    color: #fff !important;
}

.k-widget {
    border-width: 1px !important;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    line-height: 1.5;
    display: block;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
</style>
