<template>
<div>
    <div style="text-align:start"> {{ search.length == 1 ? search.length + ' record was found' : search.length + ' records were found'}}</div>
    <component
        :is="transition !== 'None' ? `v-${transition}` : 'div'"
        hide-on-leave
    >
            <v-data-table
                :headers="headers"
                :items-per-page="30000"
                :items="search"
                :hide-default-footer="true"
                class="elevation-2"
                @click:row="handleClick($event)"
            >
                <template v-if="searchtype.toLowerCase() !== 'opti'" v-slot:item.FIRST_NAME="{ item }">
                    <v-tooltip right>
                        <template v-slot:activator="{ on }">
                            <v-chip color="#22A49C" v-on="on">{{ item.FIRST_NAME }}</v-chip>
                        </template>
                        <ul>
                            <li> ID: <v-chip>{{ item.ID }}</v-chip></li>
                            <li >Last Name: {{ item.LAST_NAME }} </li>
                            <li>Member in Good Standing: {{ item.MEMBER_TYPE }}</li>
                            <div style = "font-weight: bold;">Internship</div><hr>
                            <li>Type: {{ item.ITraining_Type ? item.ITraining_Type : '' }}</li>
                            <li>Specialty: {{ item.ISpecialty ? item.ISpecialty : '' }}</li>
                            <li>Completion Code: {{ item.ICompletion_Code ? item.ICompletion_Code : '' }}</li>
                            <li>Actual End Date: {{ item.IActual_End_Date | formatDate }}</li>
                            <div style = "font-weight: bold;">Residency</div><hr>
                            <li >Training Type:  {{ item.RTraining_Type ? item.RTraining_Type : '' }} </li>
                            <li>Specialty: {{ item.RSpecialty ? item.RSpecialty : '' }}</li>
                            <li>Actual End Date: {{ item.RActual_End_Date | formatDate }}</li>
                            <li>Completion Code: {{ item.RCompletion_Code ? item.RCompletion_Code : '' }}</li>
                            <li>Approval Code: {{ item.RApproval_Code ? item.RApproval_Code : '' }}</li>
                            <li>Decision Date: {{ item.RDecision_Date | formatDate }}</li>
                        </ul>  
                    </v-tooltip>
                </template>

            </v-data-table>
    </component>

            <v-dialog v-model="dialog4" fullscreen>
                <v-card>
                    <v-fab-transition>
                    <v-btn
                        id="closedetail"
                        @click="dialog4 = false; drawer=false"
                        color="#336195"
                        dark
                        fixed
                        top
                        right
                        margin:4px
                        padding-right=10%
                        fab
                        >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-fab-transition>
                        <reportdetail-component 
                            :user="user"
                            :drawer="drawer"
                            :search="search"
                            :approvalcodes="approvalcodes"
                            :completioncodes="completioncodes"
                            :trainingtypes="trainingtypes">
                        >
                        </reportdetail-component>
                </v-card>
            </v-dialog>
            <v-overlay :value="overlay" opacity="0.80">
              <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
</div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import $ from 'jquery';
import '@progress/kendo-ui';
import { EventBus } from '../eventbus.js';
import moment from 'moment';
import jszip from 'jszip';

function getNestedValue(fieldName, dataItem) {
	const path = fieldName.split('.');
	let data = dataItem;
	path.forEach((p) => {
		data = data ? data[p] : undefined;
	});

	return data;
}

const componentInstance = Vue.component('template-component', {
	props: {
		field: String,
		dataItem: Object,
		format: String,
		className: String,
		columnIndex: Number,
		columnsCount: Number,
		rowType: String,
		level: Number,
		expanded: Boolean,
		editor: String,
	},
	template: ' <td :class="className" @click.once="clickHandler(dataItem)"><b>{{ getNestedValue(field, dataItem)}}</b></td>',
	methods: {
		clickHandler: function(dataItem){
			this.$emit('custom', dataItem);
		},
		getNestedValue: getNestedValue
	}
});

export default {
	name:'table2',
	props: [
		'search',
		'searchtype',
		'authuser',
		'approvalcodes',
		'completioncodes',
		'trainingtypes'
	],
	components: {

	},
	data: function () {
		return {
			cellTemplate: componentInstance,
			dialog4: false,
			dialog: false,
			drawer: false,
			transition: 'scale-transition',
			headers: [
				{ value: 'ID', text: 'ID'},
				{ value: 'FIRST_NAME', text: 'First Name' },
				{ value: 'LAST_NAME', text: 'Last Name' },
				{ value: 'STATE_PROVINCE', text: 'State' },
				{ value: 'DOB', text: 'Birth Date',  type:'date'} 
			],
			dataItems: [],
			skip: 0,
			take: 10,
			user: null,
			overlay: false,
		};
	},
	methods: {
		handleClick(e) { 
			this.customHandler(e);
		},
		getColor() {
			return 'blue';
		},
		customHandler: async function(dataItem){
			this.overlay = true;
			self = this;
			await this.$api.get('/search/user', { 
				params: dataItem.ID
			}).then(function (response) {
				self.user = response.data;
				self.user  = JSON.parse(JSON.stringify(self.user));

				self.overlay = false;
				self.dialog4 = true;
				if (!self.viewportHeight) {
					self.drawer = true;
				}
			}).catch(function (error) {
				self.errorHandler(error);
			});
		},
		getNestedValue: getNestedValue,
		pageChangeHandler: function(event) {
			this.skip = event.page.skip;
			this.take = event.page.take;
		}, 
		scrollUp (e) {
			EventBus.$emit('detail-click', 'scrollup');
		}
	},
	onDestroy() {
		this.dataItems = {};
		$('tr').off('click');
	},
	mounted() {
		this.dataItems = this.search;
	},
	updated () {
		$('tr').off('click');
	},
	computed: {
		result: {
			get: function() {
				if (typeof this.search != 'undefined') {
					return this.search.slice(this.skip, this.take + this.skip);
				}
			},
			set: function() {
				return this.search || {};
			}
		},
		total () {
			return this.search ? this.search.length : 0;
		},
		getCount() {
			if (this.search.length == 0) {
				return true;
			} 
			return false;
		},
		skeleton() {
			return this.loading;
		},
		viewportHeight () {
			switch (this.$vuetify.breakpoint.name) {
			case 'xs': return true;
			case 'sm': return false;
			case 'md': return false;
			case 'lg': return false;
			case 'xl': return false;
			}
		}
	}
};

</script>
