<template>
    <div id="vueapp" class="vue-app">
            <div class="text-align:center" :style="dynamicHeaderStyle">
                <v-btn @click="exportPdfData" color="#22A49C" dark>
                    Export To PDF
                </v-btn> 

                <v-btn @click="exportExcelData" color="#22A49C" dark>
                    Export To Excel
                </v-btn>
            </div>
        <section> 
            <v-card
            flat     
            class="mx-auto"
            width="900"
            :style="!dynamicStyle ? '' : 'left:120'"
            relative
            > 
            <v-container fluid flat>
                <v-card flat
                class="overflow-hidden"
                >
        <v-main >
        <v-navigation-drawer v-if="dynamicDrawer" 
        v-model="drawer"
        color="#336195"
        left
        fixed
        stateless
        width="290"
        dark>
        <v-list
          dense
          class="py-0"
        >
          <v-list-item three-line :class="miniVariant && 'px-0'">
              <v-list-item-title >{{ listtitle.toUpperCase()}}</v-list-item-title>
          </v-list-item>

          <v-divider></v-divider>
          <v-list-item
            v-for="item in quickitems"
            :key="item.title"
          >

            <v-list-item-content>
              <v-list-item-title >{{ item.title.toUpperCase() }}</v-list-item-title>

                        <v-card-text style="text-align: left" v-if="item.title === 'Personal Information'">

                            <span> ID: {{ user[0].ID }}  </span> <br>  

                            <span> Name: {{ user[0].FULL_NAME }} </span> <br>  

                            <span style="text no-wrap"> Member In Good Standing: {{ user[0] | getStanding }}  </span>  <br>  

                        </v-card-text>

                        <v-card-text style="text-align: left"  v-if="item.title === 'Internship' && user[0].physinterns.length">

                            <span> Type: {{ user[0].physinterns[0].Training_Type ? user[0].physinterns[0].Training_Type: '' }}  </span> <br>  

                            <span> Specialty: {{ user[0].physinterns[0].Specialty ? user[0].physinterns[0].Specialty : ''}}  </span> <br>  

                            <span> Completion Code: {{ user[0].physinterns[0].Completion_Code ? user[0].physinterns[0].Completion_Code : ''}}  </span>  <br>  
                            
                            <span> Actual End Date: {{ user[0].physinterns[0].Actual_End_Date | checkKey | formatDate }} </span>  <br> 

                            <a v-if=" user[0].physinterns.length > 1" style="color:white; text-decoration: underline" @click.prevent="scrollMeTo('physinterns')"> More Internship Information Below</a>  
 
                        </v-card-text> 
 
                        <v-card-text style="text-align: left"  v-if="item.title === 'Residency' && user[0].residency.length">
                            
                            <span> Training Type: {{ user[0].residency[0].Training_Type | getDescription(trainingtypes) }} </span> <br>  

                            <span> Specialty: {{ user[0].residency[0].Specialty }}  </span> <br>  

                            <span> Completion Code: {{ user[0].residency[0].Completion_Code | getDescription(completioncodes) }}  </span>  <br>  
                            
                            <span> Approval Code: {{ user[0].residency[0].Approval_Code | getDescription(approvalcodes)}}  </span>  <br>  

                            <span> Actual End Date: {{ user[0].residency[0].Actual_End_Date | formatDate }} </span>  <br>  
                            
                            <span> Decision Date: {{ user[0].residency[0].Decision_Date | checkKey | formatDate }} </span>  <br>

                            <a v-if=" user[0].residency.length > 1" style="color:white;text-decoration: underline" @click.prevent="scrollMeTo('residency')"> More Residency Information Below</a>  

                        </v-card-text> 
             </v-list-item-content>

          </v-list-item>
        </v-list> 

        </v-navigation-drawer>
        </v-main>
            <div id="grid" width="900" style="left:120" border=0 fluid>

                     <div id="page-template">
                        <script type="text/x-kendo-template" id="pager">
                            <div class="page-template">
                                <div class="header">
                                    <img src='images/AOA-Horz-RGB_2018.png' width="175" height="60">
                                    <br>
                                   <div> 142 E. Ontario Street, Chicago, IL 60611-2864 ph 312 202 8000 | 800 621 1773 </div>
                                </div>
  
                            <div class="watermark" >Unofficial Copy</div>

                            <div id="pdf" class="footer">
                                Page #: pageNum # of #: totalPages #
                            </div>
                            </div>
                        </script>
                        </div>
                    <v-card
                        class="d-inline-flex pa-4"
                        flat
                        scrollable=false
                    >

                    <table id="detailtable" class="tblDetail" border=0 cellspacing="5" cellpadding="2" data-cols-width="40, 40, 40">
                            <tr colspan="4">
                                <td class="headerBlue mbold" :style="dynamicStyle" data-f-color="22A49C" data-f-sz="14">
                                    {{ user[0].FULL_NAME }}
                                </td>
                            </tr>
                            <tr colspan="4">
                                <td :style="dynamicStyle" data-f-sz="14">
                                    <div style="width:auto">Printed On: {{ new Date() | formatDate }}</div>
                                </td>
                            </tr>  
                        <tr>
                            <td colspan="3" data-f-color="22A49C" data-f-sz="14">
                                <h3 class="headerBlue">Personal Information:</h3>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="1"> 
                                <span class="mbold">ID:</span> <br> {{ user[0].ID }}
                            </td>
                            <td width="50" colspan="3">
                                <span class="mbold">Member In Good Standing:</span> <br> {{ user[0] | getStanding }}
                            </td>
                            <td colspan="2" width="50">
                                <span class="mbold">Date of Birth:</span> <br>{{ user[0].biographical[0].DOB | formatDate }}
                            </td>
                        </tr>
                        <tr>
                        <td colspan="1"> 
                            <span class="mbold">Home Phone:</span> <br> {{ user[0].HOME_PHONE }}
                        </td>
                        <td colspan="3">                            
                            <span class="mbold">Work Phone:</span> <br> {{ user[0].WORK_PHONE }}
                        </td>
                        <td colspan="2">                            
                                <span class="mbold">Fax:</span> <br>{{ user[0].FAX }}
                        </td>
                        </tr>
                        <tr>                                
                            <td colspan="4">     
                                <span class="mbold">Medical School:</span> 
                                <br>
                                    {{ user[0].college | checkKey }} 

                                <v-spacer class="col-auto"></v-spacer>
                                              
                                <span class="mboldSmall  mblock">Practice Type: <span class="fontSmall"> {{ user[0].reports.practype | checkKey }} </span></span> 
                                <span class="mboldSmall  mblock">Professional Employment: <span class="fontSmall"> {{ user[0].reports.profempl | checkKey }} </span></span>
                                <span class="mboldSmall  mblock">Major Practice Focus: <span class="fontSmall"> {{ user[0].reports.pracfocus  | checkKey}} </span></span>
                            </td>

                            <td>      
                                <span class="mbold">Grad Date:</span> <br> {{ user[0].colleges.Grad_date | formatDate }}
                            </td>
                        </tr>
                        <tr>
                           <td colspan="4"> <h3 class="headerBlue">Address:</h3></td>
                        </tr>
                        <tr>
                        <td colspan="3">
                            <span class="mbold">Address Type: {{ ' ' + user[0].addresses[0].PURPOSE ? user[0].addresses[0].PURPOSE : ''  }} </span>
                        <br>
                            {{ user[0].addresses[0].FULL_ADDRESS }}
                        </td>
                        <td v-if ="user[0].addresses.length > '1'" colspan="3">
                                <span class="mbold">Address Type: {{ ' ' + user[0].addresses[1] ? user[0].addresses[1].PURPOSE : ''  }} </span>
                            <br>
                                {{ user[0].addresses[1] ?  user[0].addresses[1].FULL_ADDRESS : '' }}
                         </td>
                        </tr>
                        <tr>
                            <td colspan="4" data-f-color="22A49C" data-f-sz="14">
                                <h3 class="headerBlue">Internship:</h3>
                            </td>
                        </tr>

                        <div id="physinterns"></div>
                        <template v-for="(item, index) in user[0].physinterns">
                        <tr :key = "item[index]">
                            <td colspan="4"><span class="mbold">Type:</span>{{ item.Training_Type | checkKey }}<br></td>
                        </tr>
 
                        <tr :key = "item[index]">
                            <td colspan="4"><span class="mbold">Institution:</span> <br>
                            {{ item.Institution }}  <br>
                            {{ item.Institution_Address }}
                            </td>
                        </tr>   
                        <tr :key = "item[index]">
                            <td colspan="1"> <span class="mbold">Verification:</span> <br> {{ item.Verification | checkKey  }} <br> </td>
                         </tr>         
                        <tr :key = "item[index]">
                            <td colspan="1"><span class="mbold">Specialty:</span> <br> {{ item.Specialty | checkKey }}</td>
                            <td colspan="2"><span class="mbold">Completion Code:</span> <br> {{ item.Completion_Code }}</td> 
                        </tr>
                        <tr :key = "item[index]">
                            <td colspan="1"><span class="mbold">Start date:</span> <br> {{item.Start_Date | formatDate }}</td>
                            <td colspan="2"><span class="mbold">End date:</span> <br> {{ item.End_Date | formatDate }}</td>    
                        </tr>

                        <tr :key = "item[index]">
                            <td colspan="1"><span class="mbold">Actual Start Date:</span> <br> {{item.Actual_Start_Date | formatDate }}</td>
                            <td colspan="2"><span class="mbold">Actual End Date:</span> <br> {{ item.Actual_End_Date | formatDate }}</td>    
                        </tr>

                        <tr :key = "item[index]">
                            <td><span class="mbold">Decision Date:</span> <br> {{ item.Decision_Date | checkKey | formatDate }}</td>  
                        </tr> 

                        </template> 

                        <div id="residency"></div>
                        <template v-for="(item, index) in user[0].residency">
                         <tr :key= "item[index]">
                            <td colspan="4" data-f-color="22A49C" data-f-sz="14">
                                <h3 class="headerBlue">{{ item.MEMBER_TYPE == 'TPR' ? 'Residency' : 'Fellowship' }}:</h3>
                            </td>
                        </tr> 
                        <tr :key = "item[index]">
                            <td colspan="3"><span class="mbold">Program Name:</span> <br>  
                            {{ item.COMPANY}}
                            <br>
                            {{  item.FULL_ADDRESS  }}
                            </td>
                            <td colspan="2"><span class="mbold">Verification:</span> <br>  {{ item.Verification | checkKey | getVerification}} </td>
                        </tr>
                        <tr :key = "item[index]">
                            <td><span class="mbold">Training Type:</span> <br> {{ item.Training_Type | checkKey | getDescription(trainingtypes) }} </td>
                            <td colspan="3"><span class="mbold">Approval Code:</span> <br> {{  item.Approval_Code | checkKey | getDescription(approvalcodes) }} </td>
                        </tr>
                            <tr :key = "item[index]">
                            <td><span class="mbold">Specialty:</span> <br> {{ item.Specialty }} </td>
                            <td colspan="3"><span class="mbold">Advanced Standing (months):</span> <br> {{ item.Adv_Standing_Months }} </td>
                        </tr>                        
                            <tr :key = "item[index]">
                            <td colspan="4"><span class="mbold">Institution ID:</span> <br> {{  item.Institution_Id }} </td>
                        </tr>    
                            <tr :key = "item[index]">
                            <td colspan="1"><span class="mbold">Projected Start Date:</span> <br>  {{ item.Projected_Start_Date | formatDate }} </td>
                            <td colspan="3"><span class="mbold">Contract Start Date:</span> <br> {{ item.Contract_Start_Date | formatDate }} </td>
                            <td colspan="2"><span class="mbold">Actual Start Date:</span> <br> {{  item.Contract_Start_Date | formatDate }} </td>
                        </tr>         
                            <tr :key = "item[index]">
                            <td colspan="1"><span class="mbold">Projected End Date:</span> <br> {{ item.Projected_End_Date | formatDate }} </td>
                            <td colspan="3"><span class="mbold">Contract End Date:</span> <br> {{  item.Contract_End_Date | formatDate }} </td>
                            <td colspan="2"><span class="mbold">Actual End Date:</span> <br> {{  item.Actual_End_Date | formatDate  }} </td>
                        </tr>                                        
                            <tr :key = "item[index]">
                            <td colspan="1"><span class="mbold">Completion Code:</span> <br> {{ item.Completion_Code | checkKey | getDescription(completioncodes)}} </td>
                            <td colspan="3"><span class="mbold">Decision Date:</span> <br> {{ item.Decision_Date | checkKey | formatDate }} </td>
                            <td colspan="2"><span class="mbold">Pathway:</span> <br> {{ item.contractdata | checkKey }} </td>
                        </tr> 
                        </template> 

                        <tr>
                            <td colspan="4" data-f-color="22A49C"  data-f-sz="14">
                            <h3 class="headerBlue">Licensure Information:</h3>  
                            </td>
                            </tr>
                        <template v-for="(item, index) in user[0].license" >
                            <tr :key = "item[index]">
                                <td colspan="2" ><span class="mbold">State:</span>  {{ item.License_State | checkKey }} </td>
                            </tr>
                            <tr :key = "item[index]">
                                <td colspan="2"><span class="mbold">Disciplinary Action: {{ item.Disciplinary_Action ? "** Yes" : "** None"}}</span> </td>
                            </tr>
                            <tr :key = "item[index]">
                                <td><span class="mbold">License Number: </span> <br> {{ item.License_Number | checkKey }} </td>
                                <td colspan="2"><span class="mbold">AOA License Status: </span> <br>{{ item.AOA_License_Status }} </td>
                            </tr>                        
                            <tr :key = "item[index]">
                                <td><span class="mbold">License Date:</span>  <br> {{item.License_Date | checkKey | formatDate}} </td>
                                <td colspan="2"><span class="mbold">License Expire Date:</span> <br> {{ item.LIcense_Expire_Date | checkKey | formatDate }} </td>
                            </tr>  
                            <tr :key = "item[index]">
                                <td><span class="mbold">License Renewal Date:</span> <br> {{item.License_Renewal_Date | checkKey | formatDate }} </td>
                                <td colspan="2"><span class="mbold">Report Date:</span> <br> {{ item.Report_Date | checkKey | formatDate }} </td>
                            </tr>       
                            <tr :key = "item[index]">
                                <td colspan="4"> &nbsp; </td></tr>
                        </template>
                        <tr>
                            <td colspan="4">
                                <span>
                                    ** A "yes" indicates that the state board has, at some time, reported final disciplinary actions taken to the AOA. Since this information is historical and never removed from the AOA physician record, the Report user should contact the state board directly for current detailed information.
                                </span> 
                            </td>
                        </tr>                                         
 
                        <tr><td colspan="3" data-f-color="22A49C" data-f-sz="14">
                        <h3 class="headerBlue">Certification:</h3>  </td></tr>

                        <template v-for="(item, index) in user[0].physcerts" >
                            <tr :key = "item[index]">
                                <td><span class="mbold">Specialty:</span> <br> {{ item.Specialty | checkKey }} </td>
                                <td colspan="3"><span class="mbold">Cert Status:</span> <br> {{ item.Cert_Status | checkKey }} </td>
                            </tr>  
                            <tr :key = "item[index]">
                                <td><span class="mbold">Certificate Number:</span> <br> {{ item.Certificate_Number | checkKey }} </td>
                                <td colspan="3"><span class="mbold">Cert Type:</span> <br>  {{ item.Type }} </td>
                            </tr>                        
                            <tr :key = "item[index]">
                                <td><span class="mbold">Cert Issue Date:</span> <br> {{item.Cert_Issue_Date | formatDate}} </td>
                                <td colspan="3"><span class="mbold">Cert Expiration Date:</span> <br> {{ item.Cert_Expiration_Date | formatDate }} </td>
                            </tr>  
                            <tr :key = "item[index]">
                                <td><span class="mbold">Recert Issue Date:</span> <br> {{ item.Recert_Issue_Date | formatDate}} <br></td>
                                <td colspan="3"><span class="mbold">Recert Expiration Date:</span> <br> {{ item.Recert_Expiration_Date | formatDate }} </td>
                            </tr>      
                        </template>                       
                    </table>
                      </v-card>
                </div>   
                </v-card>
            </v-container>
        </v-card>
    </section>
</div>
</template>

<script>

window.Vue = Vue;

import $ from 'jquery';
import TableToExcel from '@linways/table-to-excel';
import Vue from 'vue';
import { EventBus } from '../eventbus.js';

    
const componentInstance = {
	name:'cellcontent',
	props: {
		dataItem: Object
	},
	template: '',
	data () {
		return {
		};
	},
};
export default {
	name: 'reportdetails',
	props: [
		'drawer',
		'user',
		'approvalcodes',
		'completioncodes',
		'trainingtypes'
	],
	filters: {
		getDescription: (value, obj) => {
			for( let h in obj) {
				if (h.trim() == value){
					return obj[h];
				}        
			}       
		}
	},
	cellTemplate: componentInstance,
	data: function () {
		return {
			clonedDrawer: _.cloneDeep(this.drawer),
			showpdf: false,
			pageNum: 0,
			miniVariant:true,
			listtitle:'Quick View',
			quickitems: [
				{ title: 'Personal Information' },
				{ title: 'Internship' },
				{ title: 'Residency' },
			],
		};
	},
	methods: {
		expandChange: function (event) {
			Vue.set(event.dataItem, event.target.$props.expandField, event.value);
		},
		exportExcelData() {
			TableToExcel.convert(document.getElementById('detailtable'));
		},
		setShowPdf() {
			return this.setShowPdf = true;
		},
		exportPdfData () {
			async () => {
				await this.setShowPdf();
			};
			var grid = $('#grid').data('kendoGrid');
			kendo.drawing.drawDOM('#grid', {
				allPages: true,
				forcePageBreak: '.page-break' ,
				paperSize: 'Letter',
				avoidLinks: true,
				margin: { top: '3cm', right: '0.5cm', bottom: '0.5cm', left: '0.5cm' },
				template: $('#pager').html(),
				scale: 0.6
			}).then(function(group){
				kendo.drawing.pdf.saveAs(group, 'report.pdf');
			});
		},
		getDate () {
			let now = new Date();
			let month = parseInt(now.getMonth()) + 1;
			now = now.getFullYear() + '-'+ month + '-' + now.getDate();
			return now;
		},
		scrollMeTo(refName) {
			document.getElementById(refName)
				.scrollIntoView({ behavior: 'smooth'});
		},
	},
	mounted () {
		// document.documentElement.style.overflow = 'hidden';
	},
	beforeMount() {
		EventBus.$on('detail-click', this.clickHandler );
	},
	onDestroy() {
		this.user = null;
		this.drawer = false;
		$(':button').off('click');
	},
	computed: {
		dynamicfooter () {  
			if (this.nopdf) {
				return  'display:\'block\'';
			}
			else {        
				return 'display:\'none\'';
			}
		},
		watermarkcomputed() {
			if (typeof pageNum != 'undefined'){
				return ' { display: block};';
			}
			return ' { display: none};';
		},
		viewportHeight () {
			switch (this.$vuetify.breakpoint.name) {
			case 'xs': return true;
			case 'sm': return false;
			case 'md': return false;
			case 'lg': return false;
			case 'xl': return false;
			}
		},
		dynamicStyle() {
			if (this.dynamicIntersect) {
				return 'left:300; width: 300; position:relative;';
			} else {
				return '';
			}
		},
		dynamicDrawer() {
			if (this.dynamicIntersect){
				return true;
			} else {
				return false;
			}
		},
		// hide buttons
		dynamicHeaderStyle() {
			if (this.dynamicIntersect) {
				return 'left:120; padding-top:10; position:relative';
			} else {
				return 'display: none;';
			}
		},
		// hide sticky
		dynamicIntersect() {
			switch (this.$vuetify.breakpoint.name) {
			case 'xs': return false;
			case 'sm': return false;
			case 'md': return true;
			case 'lg': return true;
			case 'xl': return true;
			}
		}
	}
};

</script>

<style scoped>

    #vueapp {
        max-width: 100%;
        overflow-x: hidden;
        max-height: 100%;
    }
    #userheader {
        text-align: center
    }
    scrollbar {
        display: none !important;
    }
    .k-pdf-export .k-grid-toolbar,
    .k-pdf-export .k-pager-wrap {
        display: none !important;
    }

    .k-grid .k-widget .k-display-block {
        border:0;
    }

    .k-grid table {
        margin: 10 !important;
        width: 100% !important;
        max-width: none !important;
        border-width: 0 !important;
        border-color: inherit !important;
        border-collapse: separate !important;
        border-spacing: 0 !important;
        empty-cells: show !important;
        outline: 0 !important;
        background-color: rgb(159, 159, 190,133, 133, 214) !important; 
    }

    .k-widget {
        border-width: 2px !important;
        border-style: solid !important;
        outline: 0 !important;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
        font-size: 1rem !important;
        line-height: 1.5 !important;
        display: block !important;
        -webkit-touch-callout: none !important;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important; 
    }

    .page-template {
          font-family: "DejaVu Sans", "Arial", sans-serif;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          border: 0 !important;
        }
        .page-template .header {
          position: absolute;
          top: 30px;
          left: 30px;
          right: 30px;
          /* border-bottom: 1px solid #888; */
          color: #888;
          display: inline;
          text-align: center;
        }

        .page-template .pdfaddress {
          position: absolute;
          top: 40px;
          left: 30px;
          right: 30px;
          /* border-bottom: 1px solid #888; */
          color: #888;
          display: inline;
          text-align: center;
        }

        .centerlogo {
          text-align: center;
        }

        .innerlogo {
           display: inline-block;
        }
        #grid {
            border-color: transparent;
        }
        .my-2 {
            text-align: center;
        }
        .k-grid-header {
            display: none;
        }
        .k-grid-content k-auto-scrollable {
            display: none;
        }

        
</style>

