require('./bootstrap');
require('expose-loader?$!jquery');

import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import axios from 'axios';
import { GridInstaller } from '@progress/kendo-grid-vue-wrapper';
import '@progress/kendo-ui';
import moment from 'moment';
import { Notification, Tooltip, PopupsInstaller } from '@progress/kendo-popups-vue-wrapper';
import IdleVue from 'idle-vue';
import Vuex from 'vuex';
import browserDetect from "vue-browser-detect-plugin";

Vue.use(browserDetect);
Vue.use(Vuex);

const eventsHub = new Vue();
const store = new Vuex.Store({
	state: {
		isIdle: false
	}
});

Vue.use(IdleVue, {
	eventEmitter: eventsHub,
	store,
	idleTime: 15 * 60 * 1000, //15 minutes 
	startAtIdle: false
});

Vue.config.productionTip = false;

Vue.use(Vuetify);
Vue.use(PopupsInstaller);

Vue.component('report-component', require('./components/TableDataComponent.vue').default);

Vue.component('home-component', require('./components/HomeComponent.vue').default);

Vue.component('footer-component', require('./components/FooterComponent.vue').default);

Vue.component('reportdetail-component', require('./components/TableDataComponentDetail.vue').default);

Vue.use(GridInstaller);

Vue.filter('formatDate', function(value) {
	if (value != null || typeof value !== 'undefined') {
		if (!vm.$browserDetect.isSafari) {
			return moment(new Date(value)).format('L');
		} else {
			let newdate = String(value).trim().split(' ');
			if (newdate.length > 1 ) {
				let formatted = newdate[0].split('-');
				let sdate = formatted[1]+'/'+ formatted[2]+'/'+formatted[0];
				return sdate;
			} else {
				return ' ';
			}
		}
	} else {
		return ' ';
	}
});

Vue.filter('checkKey', function(obj) {

	if (obj === null || typeof obj == 'undefined') {
		return;
	}
    
	for (var key in obj) {
		if (obj[key] === null || obj.length == 0) {
			obj[key] = '';
		}  
	}
	return obj;
});

Vue.filter('checkInput', function (value, event) {
	return  Object.values(value).every(o => o === null) 
    && event.stopPropagation();
});

Vue.filter('getVerification', function (value) {
	if (value && value.toLowerCase() == 'u') {
		return  'Unverified';
	}

	if (value && value.toLowerCase() == 'v') {
		return  'Verified';
	}
});


Vue.filter('getStanding', function (value) {
	if (value['MEMBER_TYPE'] == 'DO-M'){
		if (value['STATUS'] == 'A' || value['STATUS'] == 'ACERT' || value['STATUS'] == 'INC') {
			return 'Yes';
		} else if (value['STATUS'] == 'ID') {
			return 'Deceased';
		} else {
			return 'No';
		}
	} else {
		return 'No';
	}
});


Vue.filter('getResidency', function (value) {
	if (value == 'TPR' || !value.length ){
		return 'Residency';
	} else {
		return 'Fellowship';
	}

});

let vm = new Vue({
	el: '#app',
	filters: {
		checkInput: function(value, event) {
			return  Object.values(value).every(o => o === null) 
          && event.stopPropagation();
		}
	},
	vuetify: new Vuetify(),
	data () {
		return {
		};
	}
});

Vue.prototype.$filters = Vue.options.filters;

axios.defaults.baseURL = process.env.MIX_APP_URL;

Vue.prototype.$api = axios.create({
	baseURL: axios.defaults.baseURL
	// add caching if necessary and not interfering with results
	// headers: { 'Cache-Control': 'no-cache' },
	// adapter: cacheAdapterEnhancer( axios.defaults.adapter, { enabledByDefault: false })
});


Vue.mixin({
	methods: {
		formcheck: function (o) {
			const excluded = ['usertype', 'searchtype'];
			let filtered = Object.keys(o)
				.filter(key => !excluded.includes(key))
				.reduce((obj, key) => {
					obj[key] = o[key];
					return obj;
				}, {});
			return filtered;
		},
		getNestedValue(apidata) {
			if (apidata == ''){
				return;
			}

			let safari =  vm.$browserDetect.isSafari  ? true: false;

			const formatfunction = (safari, apidate) => {

				if (safari === false) {
					return moment(apidate).format('L');
				} else {
					let newdate = apidate.split(' ');
					let formatted = newdate[0].split('-');
					let sdate = formatted[1]+'/'+ formatted[2]+'/'+formatted[0];
					formatteddate = sdate;
					return formatteddate;
				}
			}

			apidata.forEach((d)=>{

				d.DOB = formatfunction(safari, d.DOB);

				if (d.hasOwnProperty('MEMBER_TYPE')) {
					if (d.MEMBER_TYPE == 'DO-M' && (d.STATUS == 'A' || d.STATUS == 'ACERT' || d.STATUS == 'INC') ){
						d.MEMBER_TYPE = 'Yes';
					} else if (d.MEMBER_TYPE == 'DO-M' && d.STATUS == 'ID') {
						d.MEMBER_TYPE = 'Deceased';
					} else {
						d.MEMBER_TYPE = 'No';
					}
				} else {
					d.MEMBER_TYPE =  'No';
				}
			});
  
			return apidata;
		},
		errorHandler(error) {
			self.overlay = false;

			if (axios.isCancel(error)) {
				window.location = `${process.env.MIX_APP_URL}deletetoken`;
			} 
          
			if (error.response.status == 422 ) {
				var errors = Object.values(error.response.data);
				Object.values(errors).forEach((index)=> {
					if (self.currentRef.includes('recert')) {
						self.validationErrorsRecert[Object.keys(index)] = true;
					} else {
						self.validationErrors[Object.keys(index)] = true;
					}
				});
			}

			if (error.response.status == 401 ) {
				window.location = `${process.env.MIX_APP_URL}deletetoken`;
			}
			self.showTable = false;
		},
		getFocus(e){
			var $i = {};
			if (e.target.value == 0) {
				$i = $(e.target);
				var label = $($i.parents('div.v-input')
					.find('.v-messages__message')[0]); 

				$($i.parents('div.v-input')
					.find('.v-messages.theme--light'))
					.addClass('error--text'); 

				label.show().css({'color': 'red'});
			}
		},
		getBlur(e){
			var $i = {};
			if (e.target.value == 0) {
				$i = $(e.target);

				$i.parents('div.v-input').removeClass('error--text')
					.children().find('.error--text')
					.removeClass('error--text')
					.find('.v-messages__message')
					.hide();
			}
		}
	}
});










